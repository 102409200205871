import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './TravelFrogFeatures.css';

const FeatureItem = ({ title, description, isOpen, toggle }) => {
  const backgroundColor =
    title === 'AI and LLM Integration' ? '#C1D5A4' :
    title === 'Blockchain-Based Ownership' ? '#A9D3DB' :
    title === 'Engaging Gameplay Loop' ? '#F2D388' : '#C7BCA1';

  const borderColor = isOpen ? '#004D00' : '#A89F8D'; 

  return (
    <div className="feature-item" style={{ border: `2px solid ${borderColor}` }}>
      <button
        className="feature-button"
        style={{ backgroundColor }}
        onClick={toggle}
      >
        <span className="feature-title">{title}</span>
        {isOpen ? <ChevronUp size={25} color="#6b5b35" /> : <ChevronDown size={25} color="#6b5b35" />}
      </button>
      {isOpen && (
        <div className="feature-description">
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

const TravelFrogFeatures = () => {
  const features = [
    {
      title: 'AI and LLM Integration',
      description: 'STXFWOG harnesses the power of artificial intelligence and large language models to create a dynamic, story-driven gameplay experience. The fwog\'s adventures, encounters, and correspondence are generated by advanced AI algorithms, ensuring each player\'s journey is unique and unpredictable.'
    },
    {
      title: 'Blockchain-Based Ownership',
      description: 'All game assets, including the fwogs, items, and rewards, are stored securely on the STACKX blockchain. This ensures true ownership, transparency, and tradability of in-game assets, giving players full control over their virtual pets and possessions.'
    },
    {
      title: 'Engaging Gameplay Loop',
      description: 'Players nurture their fwogs by feeding them, preparing travel supplies, and sending them on exciting journeys. As the fwogs explore the world, they send back postcards, photographs, and souvenirs, allowing players to vicariously experience their pet/s adventures.'
    },
    {
      title: 'Tokenized Economy',
      description: 'STXFWOG features a robust, multitoken economy that drives player engagement and rewards. The native tokens - Clover, Diamond, and Fwogs Coin - serve as the basis for in-game transactions, upgrades, and rewards, creating a vibrant and sustainable ecosystem.'
    }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFeature = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="features-container">
      <h1 className="features-title">Game Features of STXFWOG</h1>
      {features.map((feature, index) => (
        <FeatureItem
          key={index}
          title={feature.title}
          description={feature.description}
          isOpen={openIndex === index}
          toggle={() => toggleFeature(index)}
        />
      ))}
    </div>
  );
};

export default TravelFrogFeatures;
